import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    if (!data.id) {
      data.id = 'new';
    }
    let url = API_BASE_URL + 'help/save/' + data.id;
    return Vue.http.put(url, data);
  },
  save_help_tree (data) {
    return Vue.http.put(API_BASE_URL + 'save_help_tree/', data);
  },
  get_help_document (help_no) {
    return Vue.http.get(API_BASE_URL + 'get_help_document/?help_no=' + help_no);
  },
  get_help_tree () {
    return Vue.http.get(API_BASE_URL + 'get_help_tree/');
  },
  delete_help_with_help_no (data) {
    return Vue.http.post(API_BASE_URL + 'help/delete_help_with_help_no', data);
  },
  group_save (data) {
    var u_uuid = 'new';
    if (data.id) {
      u_uuid = data.id;
    }
    let url = API_BASE_URL + 'hospital_group_save/' + u_uuid;
    return Vue.http.put(url, data);
  }
};
