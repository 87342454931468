<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="3" lg="3">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-align-justify"></i><strong> Yardım listesi </strong>
            </div>
            <b-nav vertical>
              <b-nav-item v-if="help_tree.list" v-for="(help1, help_ind1) in help_tree.list" :key="help_ind1">
                <div :style="help_ind1 === selected_help_ind1 ? 'color:red; font-weight:bold;' : ''" @click="SelectHelp(1, help_ind1)">
                  {{ help1.no ? help1.no + '-' : '' }} {{ help1.caption }}
                  <b-button v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1" @click="delete_help(1, help_ind1)" variant="danger" size="sm"> <i class="fa fa-trash"></i> </b-button>
                </div>
                <b-nav vertical v-if="help_ind1 === selected_help_ind1">
                  <b-nav-item v-if="help1.list" v-for="(help2, help_ind2) in help1.list" :key="help_ind2">
                    <div :style="help_ind2 === selected_help_ind2 ? 'color:red; font-weight:bold;' : ''" @click="SelectHelp(2, help_ind1, help_ind2)">
                      {{ help2.no ? help2.no + '-' : '' }} {{ help2.caption }}
                      <b-button v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1" @click="delete_help(2, help_ind1, help_ind2)" variant="danger" size="sm"> <i class="fa fa-trash"></i> </b-button>
                    </div>
                    <b-nav vertical v-if="help_ind2 === selected_help_ind2">
                      <b-nav-item v-if="help2.list" v-for="(help3, help_ind3) in help2.list" :key="help_ind3">
                        <div :style="help_ind3 === selected_help_ind3 ? 'color:red; font-weight:bold;' : ''" @click="SelectHelp(3, help_ind1, help_ind2, help_ind3)">
                          {{ help3.no ? help3.no + '-' : '' }} {{ help3.caption }}
                          <b-button v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1" @click="delete_help(3, help_ind1, help_ind2, help_ind3)" variant="danger" size="sm"> <i class="fa fa-trash"></i> </b-button>
                        </div>
                      </b-nav-item>
                      <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                        <b-button @click="add_new_help(3, help_ind1, help_ind2)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle ({{ help2.no }}) </b-button>
                      </b-nav-item>
                    </b-nav>
                  </b-nav-item>
                  <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                    <b-button @click="add_new_help(2, help_ind1)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle ({{ help1.no }}) </b-button>
                  </b-nav-item>
                </b-nav>
              </b-nav-item>
              <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                <b-button @click="add_new_help(1)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle</b-button>
              </b-nav-item>
            </b-nav>
          </b-card>
        </b-col>
        <b-col sm="9" lg="9">
          <b-card ref="report_here" footer-tag="footer" header-tag="header" v-if="selected_help_ind1 !== 'none'">
            <template slot="footer" v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
              <b-button @click="save_help_data()" variant="success"> <i class="fa fa-save"></i> kaydet</b-button>
            </template>
            <div slot="header" class="printHidden">
              <b-row>
                <b-col cols="11">
                  <i class="fa fa-align-justify"></i><strong> Yardım bilgileri</strong>
                </b-col>
                <b-col cols="1">
                  <i v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') === -1" class="fa fa-print" @click="print" style="font-size:22px; cursor: pointer;"></i>
                </b-col>
              </b-row>
            </div>
            <!-- atama işlemleri -->
            <b-form-group label="" label-for="page_help_no" label-cols="3" class="printHidden">
              <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                Atama No : {{ help_no ? help_no : 'sayfa içerisinde seçim yapıldığında görünmektedir.' }}
                <b-button @click="SavePageHelpNoToHelpTree()" variant="success" v-if="help_no"> <i class="fa fa-trash"></i> bu yardıma atama yap</b-button>
              </template>
              <br>
              <b-row v-if="help_page_list.length > 0" v-for="(pagehelp, pagehelp_ind) in help_page_list" :key="'pagehelp' + pagehelp_ind">
                <b-col cols="1" v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                  <b-button variant="warning" @click="delete_page_help_list(pagehelp_ind)"><small>sil</small></b-button>
                </b-col>
                <b-col cols="11">
                  <b-button style="width: 80%;" :variant="pagehelp_ind === selected_page_help_list_ind ? 'success' : 'secondary'" @click="SelectPageHelp(pagehelp_ind)">{{ pagehelp.caption }}</b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Yardım no" label-for="help_number" label-cols="3" class="singleLine" validated>
              <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                <b-form-input type="text" class="form-control-warning" id="help_number" v-model="help_data.no" required></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen yardım nosunu giriniz</b-form-invalid-feedback>
              </template>
              <template v-else>
                {{ help_data.no }}
              </template>
            </b-form-group>
            <b-form-group label="Yardım başlığı" label-for="help_caption" label-cols="3" class="singleLine" validated>
              <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                <b-form-input type="text" class="form-control-warning" id="help_caption" v-model="help_data.caption" required></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen yardım başlığını giriniz</b-form-invalid-feedback>
              </template>
              <template v-else>
                {{ help_data.caption }}
              </template>
            </b-form-group>
            <b-form-group label="Yardım bilgileri" label-for="information" label-cols="3" class="singleLine">
              <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                <b-form-textarea id="information" v-model="help_data.information" placeholder="Yardım bilgileri..." rows="3"></b-form-textarea>
              </template>
              <template v-else>
                {{ help_data.information }}
              </template>
            </b-form-group>
            <b-row v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
              <b-col cols="3">Yeni resim ekle (jpg, jpeg, png, gif)</b-col>
              <b-col cols="9">
                <input id="help_picture" type="file" ref="fileinput" @change="addPicture()" accept=".jpg,.jpeg,.png,.gif">
              </b-col>
            </b-row>
            <b-row v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
              <b-col cols="3">Yeni video ekle (mp4)</b-col>
              <b-col cols="9">
                <input id="videofile" type="file" ref="fileinput" @change="VideoFileAdd()" accept=".mp4">
              </b-col>
            </b-row>
            <br>
            <template v-for="(pic, pic_ind) in help_data.pictures">
              <b-card class="mb-1 singleLine" no-body header-tag="header">
                <b-card-header header-bg-variant="secondary" class="p-1">
                  <b-button @click="help_data.pictures.splice(pic_ind, 1); $forceUpdate();" v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1" variant="danger"> <i class="fa fa-trash"></i> sil</b-button>
                  Resim - {{ pic_ind + 1 }})
                </b-card-header>
                <b-row :key="'information' + pic_ind">
                  <b-col cols="12">
                    <b-form-group label="Resim açıklaması" label-for="pic_information">
                      <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                        <b-form-textarea id="picture_information" v-model="pic.information" placeholder="Resim bilgileri..." rows="5"></b-form-textarea>
                      </template>
                      <template v-else>
                        {{ pic.information }}
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row :key="'image' + pic_ind">
                  <b-col cols="12">
                    <b-form-group label-for="picture">
                      <div v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                        <span style="color: red;">Resmi değiştir: </span>
                        <input :id="'pic' + pic_ind" type="file" ref="fileinput" @change="changePicture(pic_ind)">
                        <hr>
                      </div>
                      <img :src="pic.image" style="width: 100%;">
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-for="(vid, vid_ind) in help_data.videos">
              <b-card class="mb-1 singleLine" no-body header-tag="header" :key="'vid' + vid_ind">
                <b-card-header header-bg-variant="secondary" class="p-1">
                  <b-button @click="help_data.videos.splice(vid_ind, 1); $forceUpdate();" v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1" variant="danger"> <i class="fa fa-trash"></i> sil</b-button>
                  Video - {{ vid_ind + 1 }})
                </b-card-header>
                <b-row :key="'information' + vid_ind">
                  <b-col cols="12">
                    <b-form-group label="Video açıklaması" label-for="video_information">
                      <template v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                        <b-form-textarea id="video_information" v-model="vid.information" placeholder="Video bilgileri..." rows="5"></b-form-textarea>
                      </template>
                      <template v-else>
                        {{ vid.information }}
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row :key="'video' + vid_ind" class="printHidden">
                  <b-col cols="12">
                    <b-form-group label-for="video">
                      <div v-if="user.permissions_result && user.permissions_result.indexOf('help_edit') !== -1">
                        <span style="color: red;">Resmi değiştir: </span>
                        <input :id="'vid' + vid_ind" type="file" ref="fileinput" @change="changeVideo(vid_ind)" accept=".mp4">
                        <hr>
                      </div>
                      <template v-if="vid.video">
                        <video style="width: 100%;" controls :key="'vid' + vid_ind">
                          <source :src="vid.video" type="video/mp4">
                        </video>
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HelpService from '@/services/help';
import moment from 'moment';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'Help',
  components: {
    Modal
  },
  props: {
    help_no: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data() {
    return {
      selected_page_help_list_ind: '',
      help_page_list: [],
      selected_help_ind1: 'none',
      selected_help_ind2: 'none',
      selected_help_ind3: 'none',
      selected_level: '',
      help_tree: {},
      help_data: { 'caption': '', 'information': '', 'pictures': [], 'videos': [], 'no': '' },
      user: {}
    }
  },
  created: function() {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function() {
    this.get_help_tree();
  },
  methods: {
    print: function() {
      // let x = new Date();
      let updated_at = moment(this.help_data.updated_at.toString()).format('DD/MM/YYYY HH:mm');
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', this.help_data.caption)
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', 'Döküman güncellenme tarihi: ' + updated_at);
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function() {
        w.focus();
        w.print();
      };
    },
    delete_page_help_list: function(pagehelp_ind) {
      if (this.help_page_list[pagehelp_ind].level === 1) {
        this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].page_help_no.splice(this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].page_help_no.indexOf(this.help_no), 1);
      } else if (this.help_page_list[pagehelp_ind].level === 2) {
        this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].list[this.help_page_list[pagehelp_ind].help_ind2].page_help_no.splice(this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].list[this.help_page_list[pagehelp_ind].help_ind2].page_help_no.indexOf(this.help_no), 1);
      } else if (this.help_page_list[pagehelp_ind].level === 3) {
        this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].list[this.help_page_list[pagehelp_ind].help_ind2].list[this.help_page_list[pagehelp_ind].help_ind3].page_help_no.splice(this.help_tree.list[this.help_page_list[pagehelp_ind].help_ind1].list[this.help_page_list[pagehelp_ind].help_ind2].list[this.help_page_list[pagehelp_ind].help_ind3].page_help_no.indexOf(this.help_no), 1);
      }
      HelpService.save_help_tree(this.help_tree)
        .then(resp => {
          this.help_tree = resp.data.result;
          this.create_page_help_list();
          alert('kayıt tamamlandı');
        });
    },
    SelectPageHelp(pagehelp_ind) {
      this.selected_page_help_list_ind = pagehelp_ind;
      this.selected_level = this.help_page_list[pagehelp_ind].level;
      if (this.selected_level === 1) {
        this.selected_help_ind1 = this.help_page_list[pagehelp_ind].help_ind1;
        this.SelectHelp(1, this.selected_help_ind1);
      } else if (this.selected_level === 2) {
        this.selected_help_ind1 = this.help_page_list[pagehelp_ind].help_ind1;
        this.selected_help_ind2 = this.help_page_list[pagehelp_ind].help_ind2;
        this.SelectHelp(2, this.selected_help_ind1, this.selected_help_ind2);
      } else if (this.selected_level === 3) {
        this.selected_help_ind1 = this.help_page_list[pagehelp_ind].help_ind1;
        this.selected_help_ind2 = this.help_page_list[pagehelp_ind].help_ind2;
        this.selected_help_ind3 = this.help_page_list[pagehelp_ind].help_ind3;
        this.SelectHelp(3, this.selected_help_ind1, this.selected_help_ind2, this.selected_help_ind3);
      }
    },
    create_page_help_list: function() {
      this.help_page_list = [];
      if (this.help_no !== '') {
        for (let help_ind1 in this.help_tree.list) {
          if (this.help_tree.list[help_ind1].page_help_no && this.help_tree.list[help_ind1].page_help_no.indexOf(this.help_no) !== -1) {
            this.help_page_list.push({ 'caption': this.help_tree.list[help_ind1].caption, 'level': 1, 'help_ind1': parseInt(help_ind1) });
          }
          for (let help_ind2 in this.help_tree.list[help_ind1].list) {
            if (this.help_tree.list[help_ind1].list[help_ind2].page_help_no && this.help_tree.list[help_ind1].list[help_ind2].page_help_no.indexOf(this.help_no) !== -1) {
              this.help_page_list.push({ 'caption': this.help_tree.list[help_ind1].list[help_ind2].caption, 'level': 2, 'help_ind1': parseInt(help_ind1), 'help_ind2': parseInt(help_ind2) });
            }
            for (let help_ind3 in this.help_tree.list[help_ind1].list[help_ind2].list) {
              if (this.help_tree.list[help_ind1].list[help_ind2].list[help_ind3].page_help_no && this.help_tree.list[help_ind1].list[help_ind2].list[help_ind3].page_help_no.indexOf(this.help_no) !== -1) {
                this.help_page_list.push({ 'caption': this.help_tree.list[help_ind1].list[help_ind2].list[help_ind3].caption, 'level': 3, 'help_ind1': parseInt(help_ind1), 'help_ind2': parseInt(help_ind2), 'help_ind3': parseInt(help_ind3) });
              }
            }
          }
        }
        if (this.help_page_list.length > 0) {
          this.SelectPageHelp(0);
        }
      }
    },
    SavePageHelpNoToHelpTree: function() {
      if (this.selected_level === 1) {
        if (!this.help_tree.list[this.selected_help_ind1].page_help_no) {
          this.help_tree.list[this.selected_help_ind1].page_help_no = [];
        }
        if (this.help_tree.list[this.selected_help_ind1].page_help_no.indexOf(this.help_no) === -1) {
          this.help_tree.list[this.selected_help_ind1].page_help_no.push(this.help_no);
        }
      } else if (this.selected_level === 2) {
        if (!this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].page_help_no) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].page_help_no = [];
        }
        if (this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].page_help_no.indexOf(this.help_no) === -1) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].page_help_no.push(this.help_no);
        }
      } else if (this.selected_level === 3) {
        if (!this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].page_help_no) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].page_help_no = [];
        }
        if (this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].page_help_no.indexOf(this.help_no) === -1) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].page_help_no.push(this.help_no);
        }
      }
      HelpService.save_help_tree(this.help_tree)
        .then(resp => {
          this.help_tree = resp.data.result;
          this.create_page_help_list();
          alert('kayıt tamamlandı');
        });
    },
    delete_help(level, help_ind1, help_ind2 = '', help_ind3 = '') {
      if (confirm('Yardım bilgisini silmek istediğinizden emin misiniz?')) {
        let help_no = '';
        if (level === 1) {
          help_no = this.help_tree.list[help_ind1].no;
          this.help_tree.list.splice(help_ind1, 1);
        } else if (level === 2) {
          help_no = this.help_tree.list[help_ind1].list[help_ind2].no;
          this.help_tree.list[help_ind1].list.splice(help_ind2, 1);
        } else if (level === 3) {
          help_no = this.help_tree.list[help_ind1].list[help_ind2].list[help_ind3].no;
          this.help_tree.list[help_ind1].list[help_ind2].list.splice(help_ind3, 1);
        }
        HelpService.save_help_tree(this.help_tree)
          .then(resp => {
            this.help_tree = resp.data.result;
            if (this.help_tree.list.length > 0) {
              this.SelectHelp(1, 0);
            }
            alert('kayıt tamamlandı');
          });
        let data = { 'help_no': help_no };
        HelpService.delete_help_with_help_no(data)
          .then(resp => {
            console.log(resp.data.status);
            alert(help_no + ' nolu kayıt silindi');
          });
      }
    },
    SelectHelp(level, help_ind1, help_ind2 = '', help_ind3 = '') {
      this.help_data.information = '';
      this.help_data.pictures = [];
      this.help_data.videos = [];
      this.selected_level = level;
      this.selected_help_ind1 = help_ind1;
      this.selected_help_ind2 = help_ind2;
      this.selected_help_ind3 = help_ind3;
      let help_no = '';
      if (this.selected_level === 1) {
        help_no = this.help_tree.list[this.selected_help_ind1].no;
      } else if (this.selected_level === 2) {
        help_no = this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].no;
      } else if (this.selected_level === 3) {
        help_no = this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].no;
      }
      if (help_no !== '') {
        HelpService.get_help_document(help_no)
          .then(resp => {
            if (resp.data.result.length > 0) {
              this.help_data = resp.data.result[0];
            } else {
              this.help_data = { 'caption': 'Yeni', 'information': '', 'pictures': [], 'videos': [], 'no': '' };
            }
            this.$forceUpdate();
          });
      } else {
        this.help_data = { 'caption': 'Yeni', 'information': '', 'pictures': [], 'videos': [], 'no': '' };
        this.$forceUpdate();
      }
    },
    add_new_help: function(level, help_ind1 = '', help_ind2 = '') {
      this.selected_level = level;
      if (level === 1) {
        if (!this.help_tree.list) {
          this.help_tree.list = [];
        }
        this.help_tree.list.push({ 'caption': 'Yeni ', 'information': '', 'no': '' })
        this.selected_help_ind1 = this.help_tree.list.length - 1;
      } else if (level === 2) {
        if (!this.help_tree.list[help_ind1].list) {
          this.help_tree.list[help_ind1].list = [];
        }
        this.help_tree.list[help_ind1].list.push({ 'caption': 'Yeni ', 'information': '', 'no': '' })
        this.selected_help_ind1 = help_ind1;
        this.selected_help_ind2 = this.help_tree.list[help_ind1].list.length - 1;
      } else if (level === 3) {
        if (!this.help_tree.list[help_ind1].list[help_ind2].list) {
          this.help_tree.list[help_ind1].list[help_ind2].list = [];
        }
        this.help_tree.list[help_ind1].list[help_ind2].list.push({ 'caption': 'Yeni ', 'information': '', 'no': '' })
        this.selected_help_ind1 = help_ind1;
        this.selected_help_ind2 = help_ind2;
        this.selected_help_ind3 = this.help_tree.list[help_ind1].list[help_ind2].list.length - 1;
      }
      this.help_data = { 'caption': 'Yeni', 'information': '', 'pictures': [], 'videos': [], 'no': '' };
      this.$forceUpdate();
    },
    get_help_tree: function() {
      HelpService.get_help_tree()
        .then(resp => {
          if (resp.data.result.length > 0) {
            this.help_tree = resp.data.result[0];
            if (this.help_tree.list.length > 0) {
              if (this.help_no !== '') {
                this.create_page_help_list();
              } else {
                this.SelectHelp(1, 0);
              }
            }
          }
        });
    },
    save_help_data: function() {
      if (this.help_data.no === '' || this.help_data.caption === '' || this.help_data.information === '') {
        alert('Lütfen alanları doldurunuz');
      } else {
        HelpService.save(this.help_data)
          .then(resp => {
            console.log(resp.data);
          });
        HelpService.save_help_tree(this.help_tree)
          .then(resp => {
            this.help_tree = resp.data.result;
            alert('kayıt tamamlandı');
          });
      }
    },
    changeVideo(vid_ind) {
      if (document.getElementById('vid' + vid_ind).value) {
        let arr = document.getElementById('vid' + vid_ind).value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['mp4'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('vid' + vid_ind).value = '';
          alert('Lütfen mp4 formatını kullanınız');
          return;
        }
        var files = document.getElementById('vid' + vid_ind).files;
        if (!files.length) {
          return;
        }
        if (files[0].size > 10000000) {
          alert(this.$t('wdm16.12733'));
          return;
        }
        var vm = this;
        this.help_data.videos[vid_ind].video = '';
        var reader = new FileReader();
        reader.onload = (e) => {
          vm.help_data.videos[vid_ind].video = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    VideoFileAdd: function() {
      if (document.getElementById('videofile').value) {
        let arr = document.getElementById('videofile').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['mp4'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('videofile').value = '';
          alert('Lütfen mp4 formatını kullanınız');
          return;
        }
        var files = document.getElementById('videofile').files;
        if (!files.length) {
          return;
        }
        if (files[0].size > 10000000) {
          document.getElementById('videofile').value = '';
          alert(this.$t('wdm16.12733'));
          return;
        }
        var reader = new FileReader();
        var vm = this;
        if (!this.help_data.videos) {
          this.help_data.videos = [];
        }
        this.help_data.videos.push({ 'video': '', 'information': '' });
        reader.onloadstart = (e) => {
          console.log('started');
        };
        reader.onload = (e) => {
          vm.help_data.videos[vm.help_data.videos.length - 1].video = e.target.result;
        };
        reader.onloadend = (e) => {
          console.log('ended');
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    changePicture(pic_ind) {
      if (document.getElementById('pic' + pic_ind).value) {
        let arr = document.getElementById('pic' + pic_ind).value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['jpg', 'jpeg', 'png', 'gif'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('pic' + pic_ind).value = '';
          alert('Lütfen jpg, jpeg, png ya da gif formatını kullanınız');
          return;
        }
        var files = document.getElementById('pic' + pic_ind).files;
        if (!files.length) {
          return;
        }
        if (files[0].size > 10000000) {
          alert(this.$t('wdm16.12733'));
          return;
        }
        var vm = this;
        var reader = new FileReader();
        reader.onload = (e) => {
          vm.help_data.pictures[pic_ind].image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    addPicture() {
      if (document.getElementById('help_picture').value) {
        let arr = document.getElementById('help_picture').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['jpg', 'jpeg', 'png', 'gif'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('help_picture').value = '';
          alert('Lütfen jpg, jpeg, png ya da gif formatını kullanınız');
          return;
        }
        var files = document.getElementById('help_picture').files;
        if (!files.length) {
          return;
        }
        if (files[0].size > 10000000) {
          document.getElementById('help_picture').value = '';
          alert(this.$t('wdm16.12733'));
          return;
        }
        var reader = new FileReader();
        var vm = this;
        this.help_data.pictures.push({ 'image': '', 'information': '' });
        reader.onload = (e) => {
          vm.help_data.pictures[vm.help_data.pictures.length - 1].image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  watch: {
    'help_data.caption': function() {
      if (this.selected_level) {
        if (this.selected_level === 1) {
          this.help_tree.list[this.selected_help_ind1].caption = this.help_data.caption;
        } else if (this.selected_level === 2) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].caption = this.help_data.caption;
        } else if (this.selected_level === 3) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].caption = this.help_data.caption;
        }
      }
    },
    'help_data.no': function() {
      if (this.selected_level) {
        if (this.selected_level === 1) {
          this.help_tree.list[this.selected_help_ind1].no = this.help_data.no;
        } else if (this.selected_level === 2) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].no = this.help_data.no;
        } else if (this.selected_level === 3) {
          this.help_tree.list[this.selected_help_ind1].list[this.selected_help_ind2].list[this.selected_help_ind3].no = this.help_data.no;
        }
      }
    }
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .singleLine {
        width: 100%;
        display: inline-block;
      }
      .printHidden {
        display:none;
      }
      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        text-align:center;
        font-weight: bold;
        font-style: italic;
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    table, td, tr {
      border: 1px solid black;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>
